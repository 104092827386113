import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

// Utilities
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';


// Material Components
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Drawer,
    Hidden,
    Paper,
    Typography
} from '@mui/material';
import { getEnergyScore } from '../../../../actions';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'left',
    },
    mobileDrawer: {
        width: 300
    },
    desktopDrawer: {
        backgroundColor: "#f5f5f5"
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64
    },
    boxSize: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        padding: "24px",
        gap: '16px',
        width: "268px",
        height: "165px",
        background: "#FFFFFF",
        borderRadius: "10px",
    },
    scaleDown: {
        transform: 'scale(.95)'
    },
    height: {
        height: '45px'
    },
    radius: {
        borderRadius: '20px',
        boxShadow: '-8px 13px 8px 4px rgb(0 0 0 / 37%)',
    },
    underlinedBtnText: {
        transform: 'translateY(-2px)',
        position: 'relative',
        textTransform: 'capitalize',
        fontSize: '.9rem',
        '&::after': {
            content: "''",
            position: 'absolute',
            bottom: 0,
            left: 0,
            backgroundColor: 'currentColor',
            height: 1,
            width: '100%'
        }
    },
    marginRightAuto: {
        marginRight: 'auto'
    },
    drawer: {
        width: '100%',
        maxWidth: 560
    },
    topLabel: {
        fontStyle: "normal",
        fontFamily: 'Metropolis',
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "16px",
        color: "#161616",
        width: "102px",
        height: "16px",
    },
    cartLabel: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "16px",
        color: "#ffffff",
        width: "102px",
        height: "16px",
    },
    mainLabel: {
        fontSize: "18px",
        fontWeight: 900,
        lineHeight: "24px",
        background: "-webkit-linear-gradient(53.09deg, #122E91 6.51%, #02A29F 80.84%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent"
    },
    fuelCartLabel: {
        fontSize: "24px",
        fontWeight: 900,
        lineHeight: "24px",
        // color: "#ffffff",
        background: "-webkit-linear-gradient(53.09deg, #ffffff 6.51%, #ffffff 80.84%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent"
    },
    label2: {
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "14px",
        fontStyle: "normal",
        color: '#5C5C5C'
    },
}));

const SideBar = ({ onMobileClose, openMobile }) => {

    const classes = useStyles();
    const location = useLocation();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);

    const session = useSelector(state => state?.session);
    const energy = useSelector(state => state?.getEnergy)

    const handleOpen = () => {
        dispatch(getEnergyScore({
            businessName: session?.detailsOfCustomer?.name,
            mobile: session?.detailsOfCustomer?.mobile,
            email: session?.detailsOfCustomer?.email
        }))
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }


    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);


    const content = (
        <div style={{ backgroundColor: "#f5f5f5" }}>
            <Container className={classes.root}>
                <Box height="100%" display="flex" flexDirection="column" backgroundColor="#f5f5f5">
                    <Box mt={3} mb={8}>
                    </Box>
                    {/* <Box mt={3}>
                        <div>
                            <img
                                alt="Kyc Card"
                                src="/static/images/contentkyc.svg"
                                style={{ width: '268px', borderRadius: "10px", height: '230px' }}
                                onClick={handleOpen}
                            />
                        </div>
                    </Box> */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                                width: "255px",
                                height: "170px",
                                gap: '16px'
                            },
                        }}
                    >
                        {/*{!(session.detailsOfCustomer?.energy_score > 0) &&*/}
                        {/*    <Paper style={{borderRadius: '10px'}}>*/}

                        {/*        <Box*/}
                        {/*            display="flex"*/}
                        {/*            flexDirection="column"*/}
                        {/*            justifyContent={"center"}*/}
                        {/*            alignItems={'flex-start'}*/}
                        {/*            style={{padding: '24px'}}*/}
                        {/*        >*/}
                        {/*            <Box width={"187px"} height={'24px'}>*/}
                        {/*                <label className={classes.topLabel}>*/}
                        {/*                    KNOW MORE*/}
                        {/*                </label>*/}
                        {/*            </Box>*/}
                        {/*            <Box width={"187px"} height={'24px'}>*/}
                        {/*                <label className={classes.mainLabel}>*/}
                        {/*                    REPOS FUEL DATUM*/}
                        {/*                </label>*/}
                        {/*            </Box>*/}
                        {/*            /!*<Box width={"187px"} height={'24px'}>*!/*/}
                        {/*            /!*    <label className={classes.label2}>*!/*/}
                        {/*            /!*        Upgrade to Repos Pay & enjoy credit*!/*/}
                        {/*            /!*    </label>*!/*/}
                        {/*            /!*</Box>*!/*/}
                        {/*        </Box>*/}
                        {/*        <Box*/}
                        {/*            display="flex"*/}
                        {/*            flexDirection="column"*/}
                        {/*            justifyContent={"center"}*/}
                        {/*            alignItems={'flex-start'}*/}
                        {/*            style={{marginLeft: '24px'}}*/}
                        {/*        >*/}
                        {/*            <Box width={"265px"} height={'165px'} cursor="pointer">*/}
                        {/*                <a href={"https://fueldatum.reposenergy.com/products/repos-fuel-datum-z"}*/}
                        {/*                   style={{textDecoration: 'none', textTransform: 'none'}} target="_blank">*/}
                        {/*                    <Typography variant="body2" color="#02A29F" textTransform={"none"}>*/}
                        {/*                        Book Now >*/}
                        {/*                    </Typography>*/}
                        {/*                </a>*/}
                        {/*            </Box>*/}
                        {/*        </Box>*/}

                        {/*    </Paper>}*/}

                        <Box component={RouterLink} to={"/addReferral"} mt={1} onClick={() => {
                            mixpanel.track('Referral Banner Clicked', {
                                'Banner Type': 'Blue Banner'
                            });
                        }}>
                            <img
                                alt="Referral"
                                src="/static/images/banerAd/referral_banner.svg"
                                style={{ width: '255px', borderRadius: "10px", height: '255px' }}
                            />
                        </Box>

                    </Box>
                    <Box mt={12}>
                        <div>
                            <img
                                alt="Fuel Card"
                                src="/static/images/banerAd/Banner.svg"
                                style={{ width: '255px', borderRadius: "10px", height: '255px' }}
                            />
                        </div>
                    </Box>
                    <Box component={RouterLink} to={"/addReferral"} mt={1} onClick={() => {
                        mixpanel.track('Referral Banner Clicked', {
                            'Banner Type': 'Blue Banner'
                        });
                    }}>
                        <img
                            alt="Referral"
                            src="/static/images/banerAd/referral_banner_blue.svg"
                            style={{ width: '255px', borderRadius: "10px", height: '255px' }}
                        />
                    </Box>
                    {/*<Box mt={3}*/}
                    {/*     sx={{*/}

                    {/*         display: 'flex',*/}
                    {/*         flexWrap: 'wrap',*/}
                    {/*         '& > :not(style)': {*/}
                    {/*             width: "255px",*/}
                    {/*             height: "170px",*/}
                    {/*             gap: '16px'*/}
                    {/*         },*/}
                    {/*     }}*/}
                    {/*>*/}
                    {/*<div>*/}
                    {/*    <Paper style={{*/}
                    {/*        borderRadius: '10px',*/}
                    {/*        background: "-webkit-linear-gradient(360deg, rgb(2 15 13) 6.51%, rgb(31 139 138) 80.84%)"*/}
                    {/*    }}>*/}
                    {/*        <Box*/}
                    {/*            display="flex"*/}
                    {/*            flexDirection="column"*/}
                    {/*            justifyContent={"center"}*/}
                    {/*            alignItems={'flex-start'}*/}
                    {/*            style={{padding: '24px'}}*/}
                    {/*        >*/}
                    {/*            <Box width={"187px"} height={'24px'}>*/}
                    {/*                <label className={classes.cartLabel}>*/}
                    {/*                    INTRODUCING*/}
                    {/*                </label>*/}
                    {/*            </Box>*/}
                    {/*            <Box width={"187px"} height={'24px'}>*/}
                    {/*                <label className={classes.fuelCartLabel}>*/}
                    {/*                    REPOS FUEL CARD*/}
                    {/*                </label>*/}
                    {/*            </Box>*/}
                    {/*            <Box*/}
                    {/*                marginLeft="150px"*/}
                    {/*                marginBottom="15px"*/}
                    {/*            >*/}
                    {/*                <div style={{width: '100%', height: '100%', position: 'relative'}}>*/}
                    {/*                    <div style={{*/}
                    {/*                        width: 75.48,*/}
                    {/*                        height: 47.57,*/}
                    {/*                        left: 13.58,*/}
                    {/*                        top: 0,*/}
                    {/*                        position: 'absolute',*/}
                    {/*                        transform: 'rotate(34.50deg)',*/}
                    {/*                        transformOrigin: '0 0'*/}
                    {/*                    }}>*/}
                    {/*                        <div style={{*/}
                    {/*                            width: 47.57,*/}
                    {/*                            height: 75.48,*/}
                    {/*                            left: -0,*/}
                    {/*                            top: 47.57,*/}
                    {/*                            position: 'absolute',*/}
                    {/*                            transform: 'rotate(-90deg)',*/}
                    {/*                            transformOrigin: '0 0',*/}
                    {/*                            background: 'linear-gradient(0deg, rgba(7.44, 7.44, 7.44, 0.70) 0%, rgba(7.44, 7.44, 7.44, 0.70) 100%), radial-gradient(42.03% 53.55% at 36.97% 58.48%, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0) 100%)',*/}
                    {/*                            boxShadow: '-0.13542985916137695px -0.13542985916137695px 0.20314478874206543px rgba(0, 0, 0, 0.36) inset',*/}
                    {/*                            borderRadius: 5.08,*/}
                    {/*                            backdropFilter: 'blur(16.93px)'*/}
                    {/*                        }}/>*/}
                    {/*                        <div style={{*/}
                    {/*                            width: 15.83,*/}
                    {/*                            height: 9.87,*/}
                    {/*                            left: 55.89,*/}
                    {/*                            top: 4.66,*/}
                    {/*                            position: 'absolute'*/}
                    {/*                        }}>*/}
                    {/*                            <div style={{*/}
                    {/*                                width: 9.87,*/}
                    {/*                                height: 9.87,*/}
                    {/*                                left: 0,*/}
                    {/*                                top: 0,*/}
                    {/*                                position: 'absolute',*/}
                    {/*                                opacity: 0.48,*/}
                    {/*                                background: '#3A3A3A',*/}
                    {/*                                borderRadius: 9.14*/}
                    {/*                            }}/>*/}
                    {/*                            <div style={{*/}
                    {/*                                width: 9.87,*/}
                    {/*                                height: 9.87,*/}
                    {/*                                left: 5.96,*/}
                    {/*                                top: 0,*/}
                    {/*                                position: 'absolute',*/}
                    {/*                                opacity: 0.70,*/}
                    {/*                                background: '#161616',*/}
                    {/*                                borderRadius: 9.14*/}
                    {/*                            }}/>*/}
                    {/*                        </div>*/}
                    {/*                        <div style={{*/}
                    {/*                            width: 10.03,*/}
                    {/*                            height: 7.17,*/}
                    {/*                            left: 8.75,*/}
                    {/*                            top: 15.96,*/}
                    {/*                            position: 'absolute'*/}
                    {/*                        }}>*/}
                    {/*                            <div style={{*/}
                    {/*                                width: 10.02,*/}
                    {/*                                height: 7.17,*/}
                    {/*                                left: 0.01,*/}
                    {/*                                top: 0,*/}
                    {/*                                position: 'absolute',*/}
                    {/*                                background: 'linear-gradient(120deg, #ABABAB 0%, white 31%, #BDBDBD 63%, #5E5E5E 100%)',*/}
                    {/*                                boxShadow: '-0.20314478874206543px -0.20314478874206543px 0px rgba(0, 0, 0, 0.25) inset',*/}
                    {/*                                borderRadius: 2.37*/}
                    {/*                            }}/>*/}
                    {/*                            <div style={{*/}
                    {/*                                width: 10.03,*/}
                    {/*                                height: 6.73,*/}
                    {/*                                left: 0,*/}
                    {/*                                top: 0.29,*/}
                    {/*                                position: 'absolute',*/}
                    {/*                                border: '0.20px #7D7D7D solid'*/}
                    {/*                            }}></div>*/}
                    {/*                        </div>*/}
                    {/*                        <div style={{*/}
                    {/*                            width: 2.69,*/}
                    {/*                            height: 3.53,*/}
                    {/*                            left: 22,*/}
                    {/*                            top: 18.06,*/}
                    {/*                            position: 'absolute',*/}
                    {/*                            border: '0.74px #161616 solid'*/}
                    {/*                        }}></div>*/}
                    {/*                        <div style={{*/}
                    {/*                            width: 15.94,*/}
                    {/*                            height: 3.66,*/}
                    {/*                            left: 8.76,*/}
                    {/*                            top: 39.15,*/}
                    {/*                            position: 'absolute',*/}
                    {/*                            color: '#161616',*/}
                    {/*                            fontSize: 5.80,*/}
                    {/*                            fontFamily: 'Exo 2',*/}
                    {/*                            fontWeight: '700',*/}
                    {/*                            lineHeight: 6.70,*/}
                    {/*                            wordWrap: 'break-word'*/}
                    {/*                        }}></div>*/}
                    {/*                    </div>*/}
                    {/*                    <div style={{*/}
                    {/*                        width: 100.98,*/}
                    {/*                        height: 63.65,*/}
                    {/*                        left: 17.47,*/}
                    {/*                        top: 93.16,*/}
                    {/*                        position: 'absolute',*/}
                    {/*                        transform: 'rotate(-40.50deg)',*/}
                    {/*                        transformOrigin: '0 0'*/}
                    {/*                    }}>*/}
                    {/*                        <div style={{*/}
                    {/*                            width: 63.65,*/}
                    {/*                            height: 100.98,*/}
                    {/*                            left: 0,*/}
                    {/*                            top: 63.65,*/}
                    {/*                            position: 'absolute',*/}
                    {/*                            transform: 'rotate(-90deg)',*/}
                    {/*                            transformOrigin: '0 0',*/}
                    {/*                            background: 'linear-gradient(0deg, rgba(7.44, 7.44, 7.44, 0.70) 0%, rgba(7.44, 7.44, 7.44, 0.70) 100%), radial-gradient(42.03% 53.55% at 36.97% 58.48%, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0) 100%)',*/}
                    {/*                            boxShadow: '-0.13542985916137695px -0.13542985916137695px 0.20314478874206543px rgba(0, 0, 0, 0.36) inset',*/}
                    {/*                            borderRadius: 5.08,*/}
                    {/*                            backdropFilter: 'blur(16.93px)'*/}
                    {/*                        }}/>*/}
                    {/*                        <div style={{*/}
                    {/*                            width: 21.18,*/}
                    {/*                            height: 13.21,*/}
                    {/*                            left: 74.78,*/}
                    {/*                            top: 6.24,*/}
                    {/*                            position: 'absolute'*/}
                    {/*                        }}>*/}
                    {/*                            <div style={{*/}
                    {/*                                width: 13.21,*/}
                    {/*                                height: 13.21,*/}
                    {/*                                left: 0,*/}
                    {/*                                top: 0,*/}
                    {/*                                position: 'absolute',*/}
                    {/*                                opacity: 0.48,*/}
                    {/*                                background: '#3A3A3A',*/}
                    {/*                                borderRadius: 9.14*/}
                    {/*                            }}/>*/}
                    {/*                            <div style={{*/}
                    {/*                                width: 13.21,*/}
                    {/*                                height: 13.21,*/}
                    {/*                                left: 7.97,*/}
                    {/*                                top: 0,*/}
                    {/*                                position: 'absolute',*/}
                    {/*                                opacity: 0.70,*/}
                    {/*                                background: '#161616',*/}
                    {/*                                borderRadius: 9.14*/}
                    {/*                            }}/>*/}
                    {/*                        </div>*/}
                    {/*                        <div style={{*/}
                    {/*                            width: 13.42,*/}
                    {/*                            height: 9.59,*/}
                    {/*                            left: 11.70,*/}
                    {/*                            top: 21.36,*/}
                    {/*                            position: 'absolute'*/}
                    {/*                        }}>*/}
                    {/*                            <div style={{*/}
                    {/*                                width: 13.41,*/}
                    {/*                                height: 9.59,*/}
                    {/*                                left: 0.02,*/}
                    {/*                                top: 0,*/}
                    {/*                                position: 'absolute',*/}
                    {/*                                background: 'linear-gradient(120deg, #ABABAB 0%, white 31%, #BDBDBD 63%, #5E5E5E 100%)',*/}
                    {/*                                boxShadow: '-0.20314478874206543px -0.20314478874206543px 0px rgba(0, 0, 0, 0.25) inset',*/}
                    {/*                                borderRadius: 2.37*/}
                    {/*                            }}/>*/}
                    {/*                            <div style={{*/}
                    {/*                                width: 13.42,*/}
                    {/*                                height: 9,*/}
                    {/*                                left: 0,*/}
                    {/*                                top: 0.39,*/}
                    {/*                                position: 'absolute',*/}
                    {/*                                border: '0.20px #7D7D7D solid'*/}
                    {/*                            }}></div>*/}
                    {/*                        </div>*/}
                    {/*                        <div style={{*/}
                    {/*                            width: 3.60,*/}
                    {/*                            height: 4.72,*/}
                    {/*                            left: 29.43,*/}
                    {/*                            top: 24.17,*/}
                    {/*                            position: 'absolute',*/}
                    {/*                            border: '0.74px #161616 solid'*/}
                    {/*                        }}></div>*/}
                    {/*                        <div style={{*/}
                    {/*                            width: 21.33,*/}
                    {/*                            height: 4.89,*/}
                    {/*                            left: 11.72,*/}
                    {/*                            top: 52.37,*/}
                    {/*                            position: 'absolute',*/}
                    {/*                            color: '#161616',*/}
                    {/*                            fontSize: 5.80,*/}
                    {/*                            fontFamily: 'Exo 2',*/}
                    {/*                            fontWeight: '700',*/}
                    {/*                            lineHeight: 6.70,*/}
                    {/*                            wordWrap: 'break-word'*/}
                    {/*                        }}></div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </Box>*/}
                    {/*        </Box>*/}
                    {/*        <Box*/}
                    {/*            mt={2}*/}
                    {/*            display="flex"*/}
                    {/*            flexDirection="column"*/}
                    {/*            justifyContent={"center"}*/}
                    {/*            alignItems={'flex-start'}*/}
                    {/*            style={{marginLeft: '24px'}}*/}
                    {/*        >*/}
                    {/*            <Box height={'70px'} cursor="pointer">*/}
                    {/*                <Typography variant="body2" style={{color: '#ffff'}} onClick={handleOpen}>*/}
                    {/*                    Get an interest-free <br/> credit line for 15+7 <br/> days*/}
                    {/*                </Typography>*/}
                    {/*            </Box>*/}
                    {/*        </Box>*/}

                    {/*    </Paper>*/}
                    {/*</div>*/}
                    {/*</Box>*/}
                </Box>

                {
                    energy?.isLoading ?
                        <Dialog open={open}
                            onClose={handleClose}>
                            <CircularProgress />
                        </Dialog> :
                        <Dialog
                            open={open}
                            onClose={handleClose}
                        >
                            <DialogTitle>
                                KYC Score
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <div style={{
                                        display: 'block',
                                        width: "100%",
                                        textAlign: "center",
                                        margin: "5% auto"
                                    }}>
                                        <Typography variant="h6">
                                            Do You Want to watch your KYC score?
                                        </Typography>
                                    </div>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button

                                    onClick={handleClose}>No</Button>
                                <Button
                                    // disabled={energy?.isLoading}
                                    href={energy?.energyScore}
                                >
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>
                }
            </Container>
        </div>
    )
    return (
        <>
            {/*<Hidden lgUp>*/}
            {/*    <Drawer*/}
            {/*        anchor={"right"}*/}
            {/*        variant="temporary"*/}
            {/*        classes={{paper: classes.mobileDrawer}}*/}
            {/*        onClose={onMobileClose}*/}
            {/*        open={openMobile}*/}
            {/*    >*/}
            {/*        {content}*/}
            {/*    </Drawer>*/}
            {/*</Hidden>*/}
            <Hidden lgDown>
                <Drawer
                    anchor={"right"}
                    variant="persistent"
                    classes={{ paper: classes.desktopDrawer }}
                    open
                    style={{ zIndex: '-1' }}
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    )
}

SideBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
}
export default SideBar

